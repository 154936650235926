import { Stack, Typography } from '@mui/material'
import { TrendingDown, TrendingUp } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { EmptyCard } from '../Cards'

interface DealerRankChipProps {
  rank: number
  percentile: number
}

export function DealerRankChip({ rank, percentile }: DealerRankChipProps) {
  const isPositive = percentile > 0
  const Icon = isPositive ? TrendingUp : TrendingDown
  const color = isPositive ? 'success' : 'error'

  return (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.25 }}>
      <Link to='/reports/rank' style={{ textDecoration: 'none' }}>
        <EmptyCard direction='row' spacing={2} alignItems='center' py={1} width='fit-content'>
          <Typography variant='body3'>
            Dealer Rank: <strong>{rank}</strong>
          </Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Icon color={color} />
            <Typography variant='body3'> Vs Last Month</Typography>
          </Stack>
        </EmptyCard>
      </Link>
    </motion.div>
  )
}
