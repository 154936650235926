import { useSearchParams } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { formatMoney } from '@trinity/utils'
import { Table } from '../../components'

interface LeaderboardProps {
  leaderboard: TrinityAPI.DealerLeaderboardType[]
}

export function Leaderboard({ leaderboard }: LeaderboardProps) {
  const [params] = useSearchParams()
  const rankType = params.get('sortBy') ?? 'revenue'
  const isRevenue = rankType === 'revenue'
  const rankText = isRevenue ? 'Spend' : 'Units'

  return (
    <Stack spacing={2}>
      <Typography variant='h3'>Ranking Breakdown</Typography>
      <RadioGroup row name='sortBy' value={rankType}>
        <FormControlLabel value='revenue' control={<Radio size='small' />} label='Spend Rank' />
        <FormControlLabel value='units' control={<Radio size='small' />} label='Garment Units Rank' />
      </RadioGroup>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>{rankText} Rank</Table.Cell>
            <Table.Cell>Current {rankText}</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {leaderboard.map(data => (
            <LeaderboardRow
              key={data.rank}
              rank={data.rank}
              total={isRevenue ? formatMoney('USD', data.revenue) : data.units}
              isCurrentDealer={data.current}
            />
          ))}
        </Table.Body>
      </Table>
    </Stack>
  )
}

interface LeaderboardRowProps {
  rank: number
  total: number | string
  isCurrentDealer: boolean
}

function LeaderboardRow({ rank, total, isCurrentDealer }: LeaderboardRowProps) {
  const formattedRank = rank > 0 ? rank : 'N/A'

  return (
    <Table.Row hover>
      <Table.Cell>
        <Typography variant='h6' color='primary' component='span' sx={{ px: 2 }}>
          {formattedRank}
        </Typography>
        {isCurrentDealer && (
          <Typography variant='h6' color='primary' component='span' sx={{ fontStyle: 'italic' }}>
            Your Rank
          </Typography>
        )}
      </Table.Cell>
      <Table.Cell>
        <Typography variant='h6'>{total}</Typography>
      </Table.Cell>
    </Table.Row>
  )
}
