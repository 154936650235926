import pluralize from 'pluralize'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { EmptyCard, useGlobalState } from '@trinity/components'

export function Breakdown({ data, isCollections, isGarments }: BreakdownProps) {
  const [params] = useSearchParams()
  const timeframe = (params.get('timeframe') ?? 'mtd').toUpperCase()
  const title = isCollections ? 'Fabric Collections' : isGarments ? 'Garment' : 'Unknown'
  const subtitle = isCollections ? 'Top 5 Most Sold Fabric Collections' : null

  return (
    <BreakdownContainer timeframe={timeframe} title={title} subtitle={subtitle}>
      {data.length < 1 ? (
        <Typography>{title} Ranking only available for clothiers with sales in the given timeframe.</Typography>
      ) : isCollections ? (
        data.map(collection => (
          <BreakdownItem
            key={collection.id}
            titleText={collection.name}
            units={collection.units}
            rank={collection.rank}
            rankPercentile={collection.rankPercentile}
          >
            <img src={collection.image} alt={collection.name} height={75} width={75} />
          </BreakdownItem>
        ))
      ) : isGarments ? (
        data.map(garmentType => (
          <BreakdownItem
            key={garmentType.garmentType.abbreviation}
            titleText={garmentType.garmentType.formalName}
            units={garmentType.units}
            rank={garmentType.rank}
            rankPercentile={garmentType.rankPercentile}
          >
            <img
              src={garmentType.garmentType.image ?? ''}
              alt={garmentType.garmentType.formalName}
              height={100}
              width={75}
            />
          </BreakdownItem>
        ))
      ) : null}
    </BreakdownContainer>
  )
}

export function BreakdownContainer({ timeframe, title, subtitle, children }: BreakdownContainerProps) {
  const { onMobile } = useGlobalState()

  if (onMobile) {
    return (
      <Stack spacing={2}>
        <Typography variant='body2'>{timeframe}</Typography>
        <Typography variant='h4'>{title} Breakdown</Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
        {children}
      </Stack>
    )
  }

  return (
    <EmptyCard spacing={4}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='h3'>
          {timeframe} {title} Breakdown
        </Typography>
        <Typography variant='smallBody2'>{subtitle}</Typography>
      </Stack>
      {children}
    </EmptyCard>
  )
}

function BreakdownItem({ titleText, units, rank, rankPercentile, children = null }: BreakdownItemProps) {
  const { onMobile } = useGlobalState()
  const unitsText = `${units} ${pluralize('Unit', units)} Sold`
  const rankText = rank === 1 ? 'You Were The Top Seller!' : `Top ${rankPercentile}%`

  if (onMobile) {
    return (
      <Accordion>
        <AccordionSummary
          sx={{ borderTopLeftRadius: 6, borderTopRightRadius: 6, bgcolor: 'primary.extraLight', height: 'auto' }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} width={1}>
            <Typography variant='h6'>{titleText}</Typography>
            <Typography noWrap variant='h6' overflow='visible' color='primary'>
              Rank #{rank}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            bgcolor: 'primary.main',
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2} width={1} px={4}>
            <Typography color='common.white'>{unitsText}</Typography>
            <Typography color='common.white'>{rankText}</Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <EmptyCard direction='row' justifyContent='space-between' alignItems='center' borderRadius={2}>
      <Stack direction='row' spacing={4} alignItems='center'>
        {!onMobile && children}
        <Stack>
          <Typography gutterBottom variant='h6'>
            {titleText}
          </Typography>
          <Typography variant='smallBody2'>{unitsText}</Typography>
        </Stack>
      </Stack>
      <Stack justifySelf='flex-end' alignItems='flex-end'>
        <Typography gutterBottom variant='h6' color='primary'>
          {rankText}
        </Typography>
        <Typography variant='smallBody2' align='right'>
          #{rank} Ranked Clothier
        </Typography>
      </Stack>
    </EmptyCard>
  )
}

// ------------------------------------------ TYPES ------------------------------------------
type BreakdownProps =
  | {
      isCollections: true
      isGarments?: undefined
      data: TrinityAPI.DealerCollectionRankType[]
    }
  | {
      isGarments: true
      isCollections?: undefined
      data: TrinityAPI.DealerGarmentTypesRankType[]
    }

interface BreakdownContainerProps {
  timeframe: string
  title: string
  subtitle: string | null
  children: React.ReactNode
}

interface BreakdownItemProps {
  titleText: string
  units: number
  rank: number
  rankPercentile: number
  children?: React.ReactNode
}
